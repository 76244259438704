import React, { FC } from 'react'
import { Box, Text } from '@moonpig/launchpad-components'
import { ModuleContext, ModuleTitle } from '../types'
import { useProductStore } from '../../contexts/product'

type Props = {
  module: ModuleTitle
  context: ModuleContext
}

export const TitleModule: FC<Props> = () => {
  const [{ product }] = useProductStore()

  return (
    <Box data-testid="module-title" mb={6}>
      <Text
        typography={{ xs: 'typeMobileDisplay01', md: 'typeDesktopDisplay02' }}
      >
        {product.title}
      </Text>
    </Box>
  )
}
