import {
  MULTI_CARD_CSV_LINK,
  MULTI_CARD_LEARN_MORE_LINK,
} from '@moonpig/web-core-brand/config'
import { Region } from '@moonpig/web-core-types'
import { E_CARD_SIZE_ID } from '../../../constants'
import { useProductsLocaleText } from '../../../locale'
import { Module } from '../../types'

export const createCardMultiBuyDesktopConfiguration = ({
  localise,
  storeId,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
  storeId: Region
}): Module[] => {
  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [{ __typename: 'ModuleImages' }],
      right: [
        {
          __typename: 'ModuleMultiBuyTabs',
          tabs: [
            {
              title: localise('tabs.single_card'),
              label: localise('tabs.single_card_label'),
              modules: [
                { __typename: 'ModuleVariants', type: 'radio' },
                { __typename: 'ModuleDispatch' },
                { __typename: 'ModuleButtons' },
                { __typename: 'ModuleMoreInfo' },
              ],
            },
            {
              title: localise('tabs.multi_card'),
              label: localise('tabs.multi_card_label'),
              modules: [
                { __typename: 'ModuleQuantity' },
                {
                  __typename: 'ModuleVariants',
                  type: 'dropdown',
                  unavailableVariants: [E_CARD_SIZE_ID],
                },
                { __typename: 'ModulePrice' },
                { __typename: 'ModulePromotions' },
                { __typename: 'ModuleButtons' },
                {
                  __typename: 'ModuleLink',
                  title: localise(
                    'multicard.how_to_send_to_different_addresses',
                  ),
                  href: `/${storeId}${MULTI_CARD_LEARN_MORE_LINK}`,
                  meta: {
                    intent: 'view multi-address info',
                  },
                },
                {
                  __typename: 'ModuleLink',
                  title: localise('multicard.download_csv_template'),
                  href: MULTI_CARD_CSV_LINK[storeId],
                  meta: {
                    intent: 'download csv template',
                  },
                },
              ],
            },
          ],
          meta: {
            intent: 'change quantity',
          },
        },
      ],
      ratio: '50/50',
    },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
